import React from 'react'
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core'

import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  ImageField,
  UrlField,
  TagField,
  RefreshButton,
  CreateButton
} from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Table, Space, // Icons,
  Tooltip, Popover
} from 'antd'

import {
  compareFn,
  convertSeconds,
  toColor,
  toData,
  toSec,
  stringToData,
  ticketsToColor
} from '../../fn'

const {
  // CheckCircleOutlined,
  // CloseCircleOutlined,
  // MinusCircleOutlined,
  DownCircleOutlined,
  DownCircleFilled,
  UpCircleOutlined,
  UpCircleFilled,
  ShoppingOutlined,
  ShoppingFilled,
  ShoppingCartOutlined,
  NotificationOutlined,
  NotificationFilled,
  // PoweroffOutlined,
  // SearchOutlined,
  NumberOutlined,
  // IdcardOutlined,
  ScissorOutlined
} = Icons

interface itemTicket {
  title: string
  price: string
  free: any
  single: any
  couple: any
}

export const PlannerList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,

    pagination: {
      mode: 'off'
    }
  })

  return (
    <List
      headerButtons={
        <>
          {/* <Form layout="horizontal" {...tableProps}>
            <Form.Item name="q">
                <Input
                    placeholder="ID, Title, Content, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form> */}
          <RefreshButton
            onClick={() => { window.location.reload() }}
            size='large'
          />

          <CreateButton
            size='large'
            type='primary'
          />
        </>
      }
      title={'🤖 ' + window.location.pathname.replace('/', '').toUpperCase()}
    >

      <Table
        {...tableProps}
        rowKey='id'
      >
        <Table.Column
          dataIndex={['photo']}
          render={(value: string) => (
            <ImageField
              style={{ maxWidth: '100px' }}
              value={value?.includes('http') ? value : `https://teddy.bigmoney.biz/${value}`}
            />
          )}
          title='🖼 Photo'
        />

        <Table.Column
          dataIndex='artist'
          render={(artist: any, e: any, index: any) => (
            <>
              <div style={{ minWidth: '220px', maxWidth: '300px' }}>
                <UrlField
                  target='_blank'
                  value={e.url}
                >

                  <h5>
                    🎫 {artist}
                  </h5>

                  <div>
                    <b>
                      📍 {e.location}
                    </b>
                  </div>

                  <div>
                    🗓️ {e.eventDate || e.url}
                  </div>

                  #️⃣
                  <TagField
                    color='blue'
                    value={e.eventId}
                  />

                </UrlField>
                <span>
                  {e.map
                    ? <Tooltip title='Scansione in modalita mappa'><span>🗺 Mappa</span></Tooltip>
                    : <Tooltip title='Scansione in modalita lista'><span>📄Lista</span></Tooltip>}
                </span>
                <div>
                  <Tooltip title='GlobalLessThen filtra i risultati in questo modo: dato un aggiornameto Old => New, sia con Old che New minore di X viene notificato '>
                    {e.globalLessThen ? <TagField value={'🌍 ' + String(e.globalLessThen)} color='black' icon={<ScissorOutlined />} style={{ fontWeight: 'bold' }} /> : ''}
                  </Tooltip>
                </div>
              </div>
            </>)}
          title='🎫 Event'
          sorter={{ compare: compareFn('eventDate', stringToData) }}
        />

        {/* <Table.Column dataIndex="location" title="Location" render={(value: any) => <UrlField value={value} />}/>
            <Table.Column dataIndex="eventDate" title="Event Date" render={(value: any) => <UrlField value={value} />}/> */}
        {/* <Table.Column
                dataIndex={['url']}
                title="Url"
                render={(value: any) => <UrlField value={value} />}
            /> */}
        {/* <Table.Column
                dataIndex="label"
                title="Label"
                render={(value: any[]) => (
                    <>
                        {value?.map((item) => (
                            <TagField value={item} key={item} />
                        ))}
                    </>
                )}
            /> */}
        <Table.Column
          dataIndex='tickets'
          render={(value: object, element: any) => (
            <>
              {Object.values(value ?? {}).map((item: itemTicket, index: number) => (
                <React.Fragment
                  key={index}
                >
                  <div>
                    {ticketsToColor(item, element.globalLessThen, element.modus).tags}
                    {ticketsToColor(item, element.globalLessThen, element.modus).tagArea}
                    {ticketsToColor(item, element.globalLessThen, element.modus).tagNumber}
                    {ticketsToColor(item, element.globalLessThen, element.modus).tagSingle}
                    {ticketsToColor(item, element.globalLessThen, element.modus).tagCouple}
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
          title={
            <Popover
              content={
                <div>
                  <p><TagField value='' color='cyan' icon={<NotificationFilled />} />solo notifica</p>
                  <p><TagField value='' color='cyan' icon={<NotificationOutlined />} />solo notifica disabilitata da filtro qta</p>
                  <p><TagField value='⚫️' color='default' icon={<NotificationOutlined />} /> Disabilitato manualmente</p>
                  <p><TagField value='🌍' color='default' icon={<NotificationOutlined />} /> Disabilitato da GlobalLessThan Globale</p>
                  <p><TagField value='2' color='purple' icon={<DownCircleFilled />} style={{ fontWeight: 'bold', minWidth: '40px' }} /> filtro qta minima attivo</p>
                  <p><TagField value='2' color='purple' icon={<DownCircleOutlined />} style={{ minWidth: '40px' }} />filta qta minima non attivo</p>
                  <p><TagField value='100' color='purple' icon={<UpCircleFilled />} style={{ fontWeight: 'bold', minWidth: '40px' }} /> filtro qta massima attivo</p>
                  <p><TagField value='100' color='purple' icon={<UpCircleOutlined />} style={{ minWidth: '40px' }} />filta qta massima non attivo</p>
                  <p><TagField value='10' color='gold' icon={<ShoppingCartOutlined />} style={{ fontWeight: 'bold' }} />auto carrella fino a 10 biglietti</p>
                  <p><TagField value='10' color='gold' icon={<ShoppingCartOutlined />} style={{ fontWeight: 'bold' }} />auto carrella disabilitato da filtro qta</p>
                  <p><TagField value='20' color='green' icon={<ShoppingFilled />} style={{ fontWeight: 'bold' }} />auto compra fino a 20 biglietti</p>
                  <p><TagField value='20' color='green' icon={<ShoppingOutlined />} style={{ fontWeight: 'bold' }} />auto compra disabilitato da filtro qta</p>
                  <p><TagField value='Parterre A € 287,50' color='red' />Settore esaurito</p>
                  <p><TagField value='Parterre A € 287,50' color='green' />Settore disponibile</p>
                  <p><TagField value='Parterre A € 287,50' color='default' />Settore disabilitato da filtro</p>
                  <p><TagField value='0' color='red' icon={<NumberOutlined />} />Posti non disponibili</p>
                  <p><TagField value='327' color='green' icon={<NumberOutlined />} />Posti disponibili</p>
                  <p><TagField value='🛑' color='red' icon={<NumberOutlined />} />Settore cancellato</p>
                </div>
              }
              title='Legenda'
            >
              <span>🎟 Tickets ❔</span>
            </Popover>
          }
        />

        <Table.Column
          dataIndex='frequency'
          title='⏳ Frequency'
          render={(value: any) => (<>⏳ {value}</>)}
        />

        <Table.Column
          dataIndex='lastScan'
          render={(value: any) => (
            <span style={{ color: toColor(value) }}>⏱
              {
              // eslint-disable-next-line multiline-ternary
                (value || value === '0') ? (
                  <>
                    <b>
                      {convertSeconds(toSec(value))}
                      ago
                    </b>
                    <div>{toData(value)}</div>
                  </>
                )
                  : (<b>Never</b>)
              }
            </span>

          )}
          title='⏱ Last Scan'
          sorter={{ compare: compareFn('lastScan') }}
        />
        <Table.Column
          dataIndex='lastAlert'
          render={(value: number) => (
            <span>⚠️
              {
              // eslint-disable-next-line multiline-ternary
                (value || value === 0) ? (
                  <>
                    <b>
                      {convertSeconds(toSec(value))}
                      ago
                    </b>
                    <div>{toData(value)}</div>
                  </>
                )
                  : (<b>Never</b>)
              }
            </span>

          )}
          title='⚠️ Last Alert'
          sorter={{ compare: compareFn('lastAlert') }}
        />

        <Table.Column
          dataIndex='actions'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                recordItemId={record.id}
                size='middle'
              />

              <ShowButton
                hideText
                recordItemId={record.id}
                size='middle'
              />

              <DeleteButton
                hideText
                recordItemId={record.id}
                size='middle'
              />
            </Space>
          )}
          title='🕹 Actions'
        />
      </Table>
    </List>
  )
}
