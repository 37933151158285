import type React from 'react'
import { useMemo, useState } from 'react'
import { type BaseRecord, type IResourceComponentsProps, useApiUrl, useNotification } from '@refinedev/core'
import { CustomSearchBar, filterData } from '../../components/shared/CustomSearchBar'

import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  RefreshButton,
  CloneButton,
  CreateButton
} from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Table, Space, // Form,
  // Input,
  Tag, Button, Popover, // FilterDropdown,
  // useSelects
  Select, type SelectProps,
  Tooltip,
  Progress,
  Modal,
  Checkbox
} from 'antd'

import { blo } from 'blo'

import {
  compareFn,
  convertSeconds,
  getProgressColor,
  toColor,
  toData,
  toSec
} from '../../fn'

import {
  envIntegrity
} from '../../../server/envIntegrity.js'
import { DesktopOutlined } from '@ant-design/icons'
import { type TableRowSelection } from 'antd/es/table/interface'

const {
  CloseCircleOutlined
} = Icons

export const printTable = (tableProps: any, rowSelection?: TableRowSelection<any>, sorters: any = [], sortField: any = 'cpuUsage', setSortField: any = () => { }): any => {
  return (
    <Table
      {...tableProps}
      rowKey='instance'
      rowSelection={rowSelection}
    >
      <Table.Column
        dataIndex='n'
        title='🔢 N°'
        sorter={{ compare: compareFn('id') }}
      />

      <Table.Column
        dataIndex='instance'
        title='🆔 Instance'
        render={(value: string, element: Record<string, Record<string, string>>) => {
          return (
            <>🎫
              {

                // eslint-disable-next-line operator-linebreak
                element?.env?.LAUNCHER ?
                  <a href={`https://eu-west-2.console.aws.amazon.com/cloudwatch/home?region=eu-west-2#logsV2:log-groups/log-group/production$2523${element?.env?.LAUNCHER}/log-events/${value}$3Fstart$3D-300000`} target='_blank' rel='noreferrer'>
                    {value}
                  </a>
                  : value
              }
            </>
          )
        }}
        sorter={{ compare: compareFn('instance') }}
      />

      <Table.Column
        dataIndex='env'
        title='📡 Env'
        render={(value: any) => (
          <>{
            Object.keys(value ?? {})
              .map(
                name => {
                  return <div key={name}><b>{name}:</b> <code style={{ color: 'blue' }}>{value[name]}</code></div>
                }
              )
          }
          </>)}
        sorter={{ compare: compareFn('env') }}
      />

      <Table.Column
        dataIndex='sysInfo'
        title='🖥️ SysInfo'
        render={(value: Record<string, any>) => {
          if (!value) return null
          return (
            <pre>
              <div><b>Arch:</b> {value?.arch}</div>
              <div><b>Node Version:</b> {value?.nodeVersion}</div>
              <div><b>OS Version:</b> {value?.release}</div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 70 }}><b>RAM:</b>
                  </div>
                  <Tooltip title={`${String(value?.usedRam)} /  ${String(value?.totalRam)} GB | FREE: ${String(value?.freeRam)} GB`}>
                    <Progress
                      percent={Number(((value?.usedRam / value?.totalRam) * 100).toFixed(0))}
                      strokeColor={getProgressColor((value?.usedRam / value?.totalRam) * 100)}
                    />
                  </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 70 }}><b>CPU:</b></div>
                  <Tooltip title={`${String(value?.cpuUsage)}%`}>
                    <Progress
                      percent={value?.cpuUsage}
                      strokeColor={getProgressColor(value?.cpuUsage)}
                    />
                  </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 70 }}><b>Space:</b></div>
                  <Tooltip title={`${String(value?.usedSpace)} / ${String(value?.totalSpace)} GB | FREE: ${String(value?.freeSpace)} GB`}>
                    <Progress
                      percent={Number(((value?.usedSpace / value?.totalSpace) * 100).toFixed(0))}
                      strokeColor={getProgressColor((value?.usedSpace / value?.totalSpace) * 100)}
                    />
                  </Tooltip>
                </div>
              </div>
              <div><b>Chrome Processes:</b> {value?.chromeProcesses}</div>
              <div><b>AdsPower Processes:</b> {value?.adspowerProcesses}</div>
              <div><b>SunBrowser Processes:</b> {value?.sunbrowserProcesses}</div>
              <div><b>Total Processes:</b> {value?.totalProcesses}</div>
            </pre>
          )
        }}
        sorter={{
          compare: compareFn('sysInfo', (e: any) => {
            if (sortField === 'cpuUsage') return e?.cpuUsage
            if (sortField === 'ram') return e?.usedRam / e?.totalRam
            if (sortField === 'space') return e?.usedSpace / e?.totalSpace
            return e?.[sortField]
          })
        }}
        filterDropdown={(props) => (
          <div style={{ padding: 8 }}>
            <Select
              placeholder='Select Sort Field'
              onChange={setSortField}
              defaultValue='cpuUsage'
              options={[
                { label: 'CPU', value: 'cpuUsage' },
                { label: 'RAM', value: 'ram' },
                { label: 'Space', value: 'space' },
                { label: 'Total Processes', value: 'totalProcesses' }
              ]}
              style={{ width: '100%' }}
            />
          </div>
        )}
      />

      <Table.Column
        dataIndex='scanInterval'
        title='⏳ Scan Interval'
        render={(value: any) => (<>⏳ {value}</>)}
        sorter={{ compare: compareFn('scanInterval') }}
      />

      <Table.Column
        dataIndex='netns'
        title='🔌 Netns'
        render={(value: any) => (<>🔌 {value}</>)}
        sorter={{ compare: compareFn('netns') }}
      />

      <Table.Column
        dataIndex='lastSeen'
        render={(value: any, element: any) => (
          <span style={{ color: element?.enabled === false ? 'gray' : toColor(value) }}>⏱
            {
              // eslint-disable-next-line multiline-ternary
              (value || value === '0') ? (
                <>
                  <b>
                    {convertSeconds(toSec(value))}
                    ago
                  </b>

                  <div>{toData(value)}</div>
                </>
              )
                : (<b>Never Seen</b>)
            }
          </span>

        )}
        title='⏱ Last Seen'
        sorter={{ compare: compareFn('lastSeen') }}
        sortDirections={['descend', 'ascend']}
        defaultSortOrder={sorters.filter((e: any) => e.field === 'lastSeen')[0]?.order}
      />

      <Table.Column
        dataIndex='lastStart'
        render={(value: any, element: any) => (
          <span style={{ color: element?.enabled === false ? 'gray' : toSec(value) < 300 ? '#FFBF00' : 'black' }}>🕰
            {
              // eslint-disable-next-line multiline-ternary
              (value || value === '0') ? (
                <>
                  <b>
                    {convertSeconds(toSec(value))}
                    ago
                  </b>

                  <div>{toData(value)}</div>
                </>
              )
                : <b style={{ color: 'lightgray' }}>Never Started</b>
            }
          </span>

        )}
        title='🕰 Uptime'
        sorter={{ compare: compareFn('lastStart') }}
      />

      <Table.Column
        dataIndex='commitHash'
        render={(value: string, element: any) => (

          !value
            ? null
            // eslint-disable-next-line operator-linebreak
            :
            <Popover
              content={
                <div>
                  <center style={{ padding: 20 }}>
                    <img
                      title={value}
                      src={blo(`0x${value}`, 64)}
                    />
                  </center>
                  <pre>Git Summary: {element?.gitSummary}</pre>
                </div>
              }
              title='Versione in esecuzione'
            >

              <img
                title={value}
                src={blo(`0x${value}`, 32)}
              />

            </Popover>

        )}
        title={
          <Popover
            content={
              <div>
                <p>Versione in esecuzione</p>
              </div>
            }
            title='Commit Hash'
          >
            <span>V.</span>
          </Popover>
        }
        sorter={{ compare: compareFn('commitHash') }}
      />

      <Table.Column
        dataIndex='enabled'
        title='🖥 Enabled'
        render={(value: boolean) => (
          <>{value ? '🟢 Abilitato' : '🔴 Disabilitato'}</>
        )}
        sorter={{ compare: (a: any, b: any) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1) }}
      />

      <Table.Column
        dataIndex='actions'
        render={(_, record: BaseRecord) => (
          <Space>
            <EditButton
              hideText
              recordItemId={record?.instance}
              size='middle'
            />

            <ShowButton
              hideText
              recordItemId={record?.instance}
              size='middle'
            />

            <CloneButton
              hideText
              recordItemId={record?.instance}
              size='middle'
            />

            <DeleteButton
              hideText
              recordItemId={record?.instance}
              size='middle'
            />
            <EditButton
              hideText
              resource='control'
              icon={<DesktopOutlined />}
              recordItemId={record?.instance?.includes('-watchdog') ? record?.instance : record?.instance?.includes('-') ? String(record?.instance?.split('-')[0]) + '-watchdog' : String(record?.instance) + '-watchdog'}
              size='middle'
            />
          </Space>
        )}
        title='Actions'
      />
    </Table>
  )
}

const handleBulkEnableChange = async (enabled: boolean, instances: React.Key[], API_URL: string, open: any): Promise<void> => {
  try {
    if (instances.length === 0) {
      throw new Error('No instances selected')
    }

    await fetch(`${API_URL}/status/bulk-update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        username: localStorage.getItem('username') ?? '',
        password: localStorage.getItem('password') ?? ''
      },
      body: JSON.stringify({
        instances,
        enabled
      })
    }).then(async (response) => {
      if (response.ok) {
        console.log('Response:', response)
        open?.({
          type: 'success',
          message: 'Richiesta inviata',
          description: 'Inviata richiesta di modifica dello stato'
        })
        setTimeout(() => { window.location.reload() }, 2000)
      } else {
        throw new Error('Errore nella richiesta di credito')
      }
    }).catch((error) => {
      console.error('Errore:', error)
      open?.({
        type: 'error',
        message: 'Errore',
        description: 'Errore nella richiesta di modifica dello stato'
      })
    })
    // Refresh the table data after update
  } catch (error) {
    console.error('Failed to update elements:', error)
  }
}

type TagRender = SelectProps['tagRender']

const tagRender: TagRender = ({ label, value, closable, onClose }) => {
  return (
    <Tag
      color={['blue', 'orange', 'green', 'red', 'purple', 'yellow', 'cyan'][Math.floor(Math.random() * 7)]}
      closable={closable}
      onClose={onClose}
      style={{ fontSize: 14 }}
    >
      {label}
    </Tag>
  )
}

export const StatusList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, filters, setFilters, sorters } = useTable({
    syncWithLocation: true,

    pagination: {
      mode: 'off'
    },
    sorters: {
      mode: 'off' // Disable sorters
    }
  })
  // Gets the current filter values for the fields
  const currentFilterValues = useMemo(() => {
    // Filters can be a LogicalFilter or a ConditionalFilter. ConditionalFilter not have field property. So we need to filter them.
    // We use flatMap for better type support.
    const logicalFilters = filters?.flatMap((item) =>
      'field' in item ? item : [])

    return {
      LAUNCHER: logicalFilters?.find((item) => item.field === 'LAUNCHER')?.value,
      PLATFORM: logicalFilters?.find((item) => item.field === 'PLATFORM')?.value,
      ROLE: logicalFilters?.find((item) => item.field === 'ROLE')?.value,
      COUNTRY: logicalFilters?.find((item) => item.field === 'COUNTRY')?.value
    }
  }, [filters])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEnabled, setIsEnabled] = useState(true)
  const [instances, setSelectedInstances] = useState<React.Key[]>([])
  const API_URL = useApiUrl()
  const { open } = useNotification()

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: instances,
    onChange: setSelectedInstances,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false
            }
            return true
          })
          setSelectedInstances(newSelectedRowKeys)
        }
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true
            }
            return false
          })
          setSelectedInstances(newSelectedRowKeys)
        }
      }
    ]
  }

  const [sortField, setSortField] = useState('cpuUsage')
  const [enabledField, setEnabledField] = useState<any[]>([])
  const [query, setQuery] = useState<any>()

  const filteredData = useMemo(() => {
    if (enabledField.includes('enabled')) {
      return tableProps.dataSource?.filter((record) => record.enabled === true)
    }
    if (enabledField.includes('disabled')) {
      return tableProps.dataSource?.filter((record) => record.enabled === false)
    }
    return tableProps.dataSource
  }, [tableProps.dataSource, enabledField])

  const updatedTableProps = {
    ...tableProps,
    dataSource: filterData(filteredData, query)
  }

  const hasSelected = instances.length > 0

  return (
    <>
      <List
        headerButtons={
          <>
            {hasSelected && (
              <Button
                type='primary'
                onClick={() => { setIsModalVisible(true) }}
                size='large'
              >
                Edit
              </Button>
            )}

            <div>
              <b>📊 Records: {hasSelected ? String(instances.length) + '/' + String(updatedTableProps.dataSource?.length) : updatedTableProps.dataSource?.length}</b>
            </div>
            <CustomSearchBar
              onFilter={setQuery}
            />
            {
            // eslint-disable-next-line operator-linebreak
              filters?.length || enabledField.length ?
                <Button size='large' onClick={() => { setFilters([], 'replace'); setEnabledField([]) }} danger icon={<CloseCircleOutlined />}>
                  Filters
                </Button>
                : null
            }
            <Select
              size='large'
              tagRender={tagRender}
              style={{ minWidth: 150 }}
              mode='multiple'
              placeholder='LAUNCHER'
              options={
                Object.keys(envIntegrity).map(e => { return { value: e } })
              }
              value={currentFilterValues.LAUNCHER}
              onChange={(value) => {
                setFilters([
                  {
                    field: 'LAUNCHER',
                    operator: 'eq',
                    value
                  }
                ])
              }}
            />
            <Select
              size='large'
              tagRender={tagRender}
              style={{ minWidth: 150 }}
              mode='multiple'
              placeholder='PLATFORM'
              options={
                Array.from(
                  new Set(
                    Object.values(envIntegrity).reduce((acc: any, e: any) => {
                      return acc.concat(e.PLATFORM)
                    }, []).filter((e: any) => e)
                  )
                ).map(e => { return { value: e } })
              }
              value={currentFilterValues.PLATFORM}
              onChange={(value) => {
                setFilters([
                  {
                    field: 'PLATFORM',
                    operator: 'eq',
                    value
                  }
                ])
              }}
            />
            <Select
              size='large'
              tagRender={tagRender}
              style={{ minWidth: 150 }}
              mode='multiple'
              placeholder='ROLE'
              options={
                Array.from(
                  new Set(
                    Object.values(envIntegrity).reduce((acc: any, e: any) => {
                      return acc.concat(e.ROLE)
                    }, []).filter((e: any) => e)
                  )
                ).map(e => { return { value: e } })
              }
              value={currentFilterValues.ROLE}
              onChange={(value) => {
                setFilters([
                  {
                    field: 'ROLE',
                    operator: 'eq',
                    value
                  }
                ])
              }}
            />
            <Select
              size='large'
              tagRender={tagRender}
              style={{ minWidth: 150 }}
              mode='multiple'
              placeholder='COUNTRY'
              options={
                Array.from(
                  new Set(
                    Object.values(envIntegrity).reduce((acc: any, e: any) => {
                      return acc.concat(e.COUNTRY)
                    }, []).filter((e: any) => e)
                  )
                ).map(e => { return { value: e } })
              }
              value={currentFilterValues.COUNTRY}
              onChange={(value) => {
                setFilters([
                  {
                    field: 'COUNTRY',
                    operator: 'eq',
                    value
                  }
                ])
              }}
            />
            {/* <Form layout="horizontal" {...tableProps}>
            <Form.Item name="q">
                <Input
                    placeholder="ID, Title, Content, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form> */}
            <Select
              size='large'
              style={{ minWidth: 150 }}
              placeholder='Attivi/Disattivi'
              value={enabledField}
              mode='multiple'
              onChange={(value: string[]) => {
                if (value?.includes('enabled') && value?.includes('disabled')) setEnabledField([])
                else if (value?.includes('enabled')) setEnabledField(['enabled'])
                else if (value?.includes('disabled')) setEnabledField(['disabled'])
                else setEnabledField([])
              }}
              options={[
              // { label: 'Abilitati & Disabilitati', value: 'all' },
                { label: '🟢 Abilitati', value: 'enabled' },
                { label: '🔴 Disabilitati', value: 'disabled' }
              ]}
            />

            <RefreshButton
              onClick={() => { window.location.reload() }}
              size='large'
            />

            <CreateButton
              size='large'
              type='primary'
            />
          </>
        }
        title='🖥 Status'
      >
        {printTable(updatedTableProps, rowSelection, sorters, sortField, setSortField)}
      </List>
      <Modal
        title='Bulk Update'
        open={isModalVisible}
        onOk={() => {
          void (async () => {
            await handleBulkEnableChange(isEnabled, instances, API_URL, open)
            setIsModalVisible(false)
          })()
        }}
        onCancel={() => { setIsModalVisible(false) }}
      >
        <Checkbox
          checked={isEnabled}
          onChange={(e) => { setIsEnabled(e.target.checked) }}
        >
          Enable
        </Checkbox>
      </Modal>
    </>
  )
}
