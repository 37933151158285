
export default (API_URL: string, axiosInstance: any): any => {
  return {
    login: async ({ username, password }: { username: string, password: string }) => {
      try {
        axiosInstance.defaults.headers.common = { username, password }
        const result = await axiosInstance.post(`${API_URL}/login`)
        if (result.data.result) {
          localStorage.setItem('username', username)
          localStorage.setItem('password', password)
          axiosInstance.defaults.headers.common = { username, password }
          return {
            success: true,
            redirectTo: '/dashboard',
            successNotification: {
              message: 'Login Successful',
              description: 'You have successfully logged in.'
            }
          }
        } else {
          return {
            success: false,
            error: {
              message: 'Login Error',
              name: 'Invalid email or password'
            }
          }
        }
      } catch (error: any) {
        return {
          success: false,
          error: {
            message: 'Login Error',
            name: error.response ? error.response.data.error : error
          }
        }
      }
    },
    logout: async () => {
      axiosInstance.defaults.headers.common = {
        username: undefined, password: undefined
      }
      localStorage.removeItem('username')
      localStorage.removeItem('password')
      return {
        success: true,
        redirectTo: '/login',
        successNotification: {
          message: 'Logout Successful',
          description: 'You have successfully logged out.'
        }
      }
    },
    onError: async (error: any) => {
      if (process.env.NODE_ENV !== 'development' && (error.response.status === 401 || error.response.status === 403)) {
        return {
          redirectTo: '/login',
          logout: true,
          error
        }
      }
      return { error }
    },
    check: async () => {
      const username = localStorage.getItem('username')
      const password = localStorage.getItem('password')
      if (username && password) {
        axiosInstance.defaults.headers.common = { username, password }
        return {
          authenticated: true
        }
      } else {
        return {
          authenticated: false,
          redirectTo: '/login',
          logout: true,
          error: {
            message: 'Check failed',
            name: 'User not found'
          }
        }
      }
    },
    getPermissions: async () => ['admin']
  }
}
