import type React from 'react'
import { type IResourceComponentsProps, useShow } from '@refinedev/core'
import { Show } from '@refinedev/antd'

import {
  printTable

} from './list'

export const PlannerShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const record = data?.data ?? {}

  return (
    <Show isLoading={isLoading}>
      {printTable({
        dataSource: [record],
        pagination: false
      })}
    </Show>
  )
}
