import type React from 'react'
import { type IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'

export const PlannerCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps /* queryResult */ } = useForm()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item
          label='Event Url'
          name={['url']}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Frequency between each scan in seconds '
          name={['frequency']}
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Old Less Than - Only show an alert if there is a variation and the old value is lower of this number'
          name={['oldLessThan']}
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
