import type React from 'react'
import { type IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Form } from 'antd'

import { printEdit } from './edit'

export const FansaleCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps /* queryResult */ } = useForm()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        {printEdit()}
      </Form>
    </Create>
  )
}
