import type React from 'react'
import { type IResourceComponentsProps } from '@refinedev/core'
import { Edit, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'

export const AccountEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps/* , queryResult */ } = useForm()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>

        <Form.Item
          label='🎫 Email'
          name={['user']}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='⏳ Password'
          name={['pwd']}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='⏳ Phone'
          name={['phone']}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
