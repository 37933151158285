import type React from 'react'
import { type FC, type PropsWithChildren } from 'react'

import { Card, Skeleton } from 'antd'

import { Text } from './text'

export const DashboardTotalCountCard: React.FC<{
  resource: any
  isLoading: boolean
  one?: any
  two?: any
  three?: any
}> = ({ resource, isLoading, one, two, three }) => {
  const { icon, title } = resource

  return (
    <Card
      style={{ height: '96px', padding: 0 }}
      bodyStyle={{
        padding: '8px 8px 8px 12px'
      }}
      size='small'
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          whiteSpace: 'nowrap'
        }}
      >
        {icon}
        <Text size='md' className='secondary' style={{ marginLeft: '8px' }}>
          {title}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Text
          size='lg'
          strong
          style={{
            textAlign: 'start',
            marginLeft: '12px',
            marginRight: '12px',
            marginTop: '12px',
            fontVariantNumeric: 'tabular-nums'
          }}
        >
          {isLoading ? (<Skeleton.Button style={{ marginTop: '8px', width: '74px' }} />) : (one)}
        </Text>
        <Text
          size='lg'
          strong
          style={{
            textAlign: 'start',
            marginLeft: '12px',
            marginRight: '12px',
            marginTop: '12px',
            fontVariantNumeric: 'tabular-nums'
          }}
        >
          {
            isLoading ? (<Skeleton.Button style={{ marginTop: '8px', width: '74px' }} />) : (two)
          }

        </Text>
        <Text
          size='lg'
          strong
          style={{
            textAlign: 'start',
            marginLeft: '12px',
            marginRight: '12px',
            marginTop: '12px',
            fontVariantNumeric: 'tabular-nums'
          }}
        >
          {
            isLoading ? (<Skeleton.Button style={{ marginTop: '8px', width: '74px' }} />) : (three)
          }

        </Text>
      </div>
    </Card>
  )
}

export const IconWrapper: FC<PropsWithChildren<{ color: string }>> = ({
  color,
  children
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        backgroundColor: color
      }}
    >
      {children}
    </div>
  )
}
