// import * as dotenv from 'dotenv'

import { Refine } from '@refinedev/core'
import { DevtoolsPanel, DevtoolsProvider } from '@refinedev/devtools'
import {
  notificationProvider,
  Layout,
  ErrorComponent
} from '@refinedev/antd'

import dataProvider from '@refinedev/simple-rest'
import routerProvider from '@refinedev/react-router-v6/legacy'

// import { AntdInferencer } from '@pankod/refine-inferencer/antd'

import { Title } from './components/title'
// import { BikeWhiteIcon, PizzaIcon } from 'components/icons'

import '@refinedev/antd/dist/reset.css'

import { Login } from './pages/login'
import resources from './resources'
import authProvider from './authProvider'

import axios from 'axios'

const axiosInstance = axios.create()

const API_URL =
  (
    () => {
      if (process.env.NODE_ENV === 'development') {
        if (process.env.REACT_APP_ENV === 'testnet') {
          return 'http://localhost:22222/api'
        } else {
          return 'http://localhost:11111/api'
        }
      }
      return '/api'
    }
  )()

const RefineProvider = (): any => {
  return (
    (
      <DevtoolsProvider>
        <Refine
          dataProvider={dataProvider(API_URL, axiosInstance)}
          legacyRouterProvider={{
            ...routerProvider,
            routes: [
              {
                element: <Login />,
                path: '/login'
              }
            ]
          }}
          authProvider={authProvider(API_URL, axiosInstance)}
          LoginPage={Login}
          resources={resources}
          notificationProvider={notificationProvider}
          Title={Title}
          Layout={Layout}
          catchAll={<ErrorComponent />}
          options={{
            projectId: 'HWOwA8-XQnok5-E0Bd05'
          }}
        >
          <DevtoolsPanel />
        </Refine>
      </DevtoolsProvider>
    )
  )
}

const App = (): any => {
  return RefineProvider()
}

export default App
