import React, { useState } from 'react'
import { type IResourceComponentsProps, useApiUrl, useNotification } from '@refinedev/core'
import { CustomSearchBar, filterData } from '../../components/shared/CustomSearchBar'

import {
  useTable,
  List,
  RefreshButton
} from '@refinedev/antd'

import { Table } from 'antd'

import {
  compareFn,
  convertSeconds,
  toColor,
  toData,
  toSec,
  getPhoneUpdateColor,
  getBalanceColor,
  getExpireColor,
  fetchWithAuth
} from '../../fn'

export const ConnectionsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,

    pagination: {
      mode: 'off'
    }
  })

  const { open } = useNotification()

  const API_URL = useApiUrl()

  const [query, setQuery] = useState<any>()
  const updatedTableProps = {
    ...tableProps,
    dataSource: filterData(tableProps.dataSource, query)
  }

  return (
    (
      <List
        headerButtons={
          <>
            {/* <Form layout="horizontal" {...tableProps}>
            <Form.Item name="q">
                <Input
                    placeholder="ID, Title, Content, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form> */}
            <CustomSearchBar
              onFilter={setQuery}
            />
            <RefreshButton
              resource='phonesGetCredit'
              size='large'
              onClick={() => {
                fetchWithAuth(`${API_URL}/phonesGetCredit`)
                  .then((a) => {
                    console.log(a)
                    open?.({
                      type: 'success',
                      message: 'Richiesta inviata',
                      description: 'Inviata richiesta di credito'
                    })
                  })
                  .catch(() => {
                    open?.({
                      type: 'error',
                      message: 'Errore',
                      description: 'Errore nella richiesta di credito'
                    })
                  })
              }}
            >
              Get Credit
            </RefreshButton>
            <RefreshButton
              resource='phonesGetData'
              size='large'
              onClick={() => {
                fetchWithAuth(`${API_URL}/phonesGetData`)
                  .then((a) => {
                    console.log(a)
                    open?.({
                      type: 'success',
                      message: 'Richiesta inviata',
                      description: 'Inviata richiesta dati'
                    })
                  })
                  .catch(() => {
                    open?.({
                      type: 'error',
                      message: 'Errore',
                      description: 'Errore nella richiesta dati'
                    })
                  })
              }}
            >
              Get Data
            </RefreshButton>
            <RefreshButton
              onClick={() => { window.location.reload() }}
              // resourceNameOrRouteName='phones'
              size='large'
            />

          </>
        }
        title='📱 Connections'
      >
        <Table
          {...updatedTableProps}
          rowKey='id'
        >
          <Table.Column
            dataIndex='id'
            title='📶 Connection ID'
            sorter={{ compare: compareFn('id') }}
          />

          <Table.Column
            dataIndex='tethering'
            title='🔌 Tethering'
            render={(value: any) => (
              <>🔌{
                value?.success
                  ? toSec(value?.timestamp) < 180
                    ? '🟢'
                    : '⚫️'
                  : '🔴'
              }
              </>
            )}
            sorter={{ compare: compareFn('tethering') }}
          />

          <Table.Column
            dataIndex='lastSeen'
            render={(value: any, element: any) => (
              <span style={{ color: element?.enabled === false ? 'gray' : toColor(value) }}>
                ⏱
                {
                  // eslint-disable-next-line multiline-ternary
                  (value || value === '0') ? (
                    <>
                      <b>
                        {convertSeconds(toSec(value))}
                        ago
                      </b>
                      <div>{toData(value)}</div>
                    </>
                  )
                    : (<b>Never</b>)
                }
              </span>

            )}
            title='⏱ Last Seen'
            sorter={{ compare: compareFn('lastSeen') }}
          />

          <Table.Column
            dataIndex='dependency'
            title='📡 Dependencies'
            render={(value: any[], element: any) => (
              <>
                {value?.map((item: { instance: string, disabled: boolean, lastSeen: number }) => (
                  <React.Fragment
                    key={item?.instance}
                  >
                    <div>
                      <a style={{ color: item?.disabled ? 'gray' : toColor(item?.lastSeen), fontWeight: 'bold' }} href={`/status/show/${item?.instance}`}>
                        {item?.instance}
                      </a>
                    </div>
                  </React.Fragment>
                ))}
              </>
            )}
            sorter={{ compare: compareFn('dependency') }}
          />

          <Table.Column
            dataIndex='tethering'
            title='📟 IP'
            render={(tethering: any, element: any) => (
              <pre>
                📟 {tethering?.ip}

                <div>⏱
                  {
                    // eslint-disable-next-line multiline-ternary
                    (tethering?.lastUpdatedIp || tethering?.lastUpdatedIp === '0') ? (
                      <>
                        <b>
                          {convertSeconds(toSec(tethering?.lastUpdatedIp))}
                          ago
                        </b>

                        <div>{toData(tethering?.lastUpdatedIp)}</div>
                      </>
                    )
                      : (<b>Never</b>)
                  }

                </div>
              </pre>
            )}
            sorter={{ compare: compareFn('dependency') }}
          />

          <Table.Column
            dataIndex='credit'
            title='💶 Credit'
            render={(value: any) => (<>💶 {value}</>)}
            sorter={{ compare: compareFn('credit') }}
          />

          <Table.Column
            dataIndex='creditUpdated'
            render={(value: any, element: any) => {
              const days = toSec(value) / 24 / 60 / 60
              const color = getPhoneUpdateColor(days)
              return (
                <span>⏱
                  {
                    // eslint-disable-next-line multiline-ternary
                    (value || value === '0') ? (
                      <>
                        <b style={{ color }}>
                          {convertSeconds(toSec(value))}
                          ago
                        </b>
                        {/* //! FORSE È DA FIXARE L'ANNO DELLA DATA */}
                        <div style={{ color }}>{toData(value)}</div>
                      </>
                    )
                      : (<b>Never</b>)
                  }

                </span>
              )
            }}
            title='⏱ Credit Updated'
            sorter={{ compare: compareFn('lastSeen') }}
          />

          <Table.Column
            dataIndex='balance'
            title='🌐 balance'
            render={(value: string) => {
              const numericValueMB = parseInt(value?.replace(/[^\d.-]/g, ''))
              const numericValueGB = Math.floor(numericValueMB / 1024)
              const color = getBalanceColor(numericValueGB)
              return (
                <span style={{ color, fontWeight: 'bold' }}>
                  🌐 {value}
                </span>
              )
            }}
            sorter={{ compare: compareFn('balance') }}
          />

          <Table.Column
            dataIndex='expire'
            title='🕔 expire'
            render={(expire: string, { credit }: any) => {
              return (
                <>
                  <span style={{ color: getExpireColor(credit, expire), fontWeight: 'bold' }}>{expire}</span>
                </>
              )
            }}
            sorter={{ compare: compareFn('expire') }}
          />

          <Table.Column
            dataIndex='balanceUpdated'
            render={(value: any, element: any) => {
              const days = toSec(value) / 24 / 60 / 60
              const color = getPhoneUpdateColor(days)
              return (
                <span>⏱
                  {
                    // eslint-disable-next-line multiline-ternary
                    (value || value === '0') ? (
                      <>
                        <b style={{ color }}>
                          {convertSeconds(toSec(value))}
                          ago
                        </b>
                        {/* //! FORSE È DA FIXARE L'ANNO DELLA DATA */}
                        <div style={{ color }}>{toData(value)}</div>
                      </>
                    )
                      : (<b>Never</b>)
                  }
                </span>
              )
            }}
            title='⏱ Balance Update'
            sorter={{ compare: compareFn('balanceUpdated') }}
          />

        </Table>
      </List>
    )
  )
}
