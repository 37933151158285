import { useState } from 'react'
import type React from 'react'
import { Input } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const { Search } = Input
interface SearchBarProps {
  onFilter: (filteredData: any) => void
}

const searchInObject = (obj: any, query: string): boolean => {
  if (!query) return true
  if (typeof obj === 'string') {
    return obj.toLowerCase().includes(query.toLowerCase())
  }
  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).some(value => searchInObject(value, query))
  }
  return false
}

export const filterData = (dataSource: any, query: string): any => {
  if (!query) {
    // Questo rende compatibile il componente con la funzione di filtro di status
    return dataSource
  }
  const filteredData = dataSource?.filter((item: any) => searchInObject(item, query))
  return filteredData
}

export const CustomSearchBar: React.FC<SearchBarProps> = ({ onFilter }) => {
  const [idTimeout, setIdTimeout] = useState<any>()

  return (

    <Search
      placeholder='Search anything'
      allowClear={{ clearIcon: <CloseOutlined /> }}
      enterButton
      size='large'
      onSearch={onFilter}
      onChange={(e) => {
        clearTimeout(idTimeout)
        setIdTimeout(setTimeout(() => { onFilter(e.target.value) }, 250))
      }}
    />

  )
}
