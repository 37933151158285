import React, { useState } from 'react'

import { type IResourceComponentsProps } from '@refinedev/core'
import { Edit, useForm, TagField, UrlField, ImageField } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Table, Form, Input, Radio, Card, Tooltip, type RadioChangeEvent } from 'antd'

import { ticketsToColor } from '../../fn'

const {
  // CheckCircleOutlined,
  // CloseCircleOutlined,
  MinusCircleOutlined,
  // DownCircleOutlined,
  // DownCircleFilled,
  // UpCircleOutlined,
  // UpCircleFilled,
  // ShoppingOutlined,
  ShoppingFilled,
  ShoppingCartOutlined,
  NotificationOutlined,
  NotificationFilled,
  // PoweroffOutlined,
  // SearchOutlined,
  // NumberOutlined,
  // IdcardOutlined,
  ScissorOutlined
} = Icons

interface itemTicket {
  title: string
  price: string
  free: any
  single: any
  couple: any
}

export const PlannerEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()

  // const plannerData = queryResult?.data?.data
  const plannerData = formProps?.initialValues

  const getModus = (): any => {
    const initModusObj: any = {}
    for (const name of Object.keys(plannerData?.tickets ?? {})) {
      initModusObj[name] = { title: plannerData?.tickets?.[name].title, price: plannerData?.tickets?.[name].price, value: 'notify', qta: '', min: '', max: '' }
    }
    const modusObj = plannerData?.modus ?? {}
    const finalModus = { ...initModusObj, ...modusObj }
    const names = Object.keys(finalModus)
    const modusArr = names.map(e => { return { name: e, ...finalModus[e] } })
    return modusArr
  }

  const getModusObj = (arr: any[]): object => {
    return arr.reduce((obj, item, index) => {
      const { name } = item
      // delete item.name
      obj[name] = item
      return obj
    }, {})
  }

  const getTickets = (tickets: object, key: number): any => {
    const { tags, tagArea, tagNumber, tagSingle, tagCouple } = ticketsToColor(Object.values(tickets ?? {})[key], plannerData?.globalLessThen, getModusObj(modus))
    return [tags, tagArea, tagNumber, tagSingle, tagCouple]
  }

  const arr: any[] = []
  const [modus, setModus] = useState(arr)
  const [loaded, setLoaded] = useState(Date.now())

  if (plannerData && loaded < (queryResult?.dataUpdatedAt ?? 0)) {
    setModus(getModus())
    setLoaded(queryResult?.dataUpdatedAt ?? 0)
    // plannerData.modus = undefined
  }

  const onChange = (e: RadioChangeEvent): void => {
    modus[Number(e.target.name)].value = e.target.value
    setModus(modus)
  }

  const onChangeInput = (e: any): void => {
    const arr: any[] = e.target.name.split('|')
    const n: number = Number(arr[1])
    const key: 'qta' | 'min' | 'max' = arr[0]
    modus[n][key] = e.target.value
    setModus(modus)
  }

  console.log([formProps])

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Table
        // {...formProps}
        dataSource={[formProps?.initialValues ?? {}]}
        pagination={false}
        rowKey='id'
      >
        <Table.Column
          dataIndex={['photo']}
          render={(value: string) => (
            <ImageField
              style={{ maxWidth: '100px' }}
              value={value?.includes('http') ? value : `https://teddy.bigmoney.biz/${value}`}
            />
          )}
          title='🖼 Photo'
        />

        <Table.Column
          dataIndex='artist'
          render={(artist: any, e: any, index: any) => (
            <>
              <div style={{ minWidth: '220px', maxWidth: '300px' }}>
                <UrlField
                  target='_blank'
                  value={e.url}
                >

                  <h5>
                    🎫 {artist}
                  </h5>

                  <div>
                    <b>
                      📍 {e.location}
                    </b>
                  </div>

                  <div>
                    🗓️ {e.eventDate || e.url}
                  </div>

                  #️⃣
                  <TagField
                    color='blue'
                    value={e.eventId}
                  />

                </UrlField>
                <span>
                  {e.map
                    ? <Tooltip title='Scansione in modalita mappa'><span>🗺 Mappa</span></Tooltip>
                    : <Tooltip title='Scansione in modalita lista'><span>📄Lista</span></Tooltip>}
                </span>
                <div>
                  <Tooltip title='GlobalLessThen filtra i risultati in questo modo: dato un aggiornameto Old => New, sia con Old che New minore di X viene notificato '>
                    {e.globalLessThen ? <TagField value={'🌍 ' + String(e.globalLessThen)} color='black' icon={<ScissorOutlined />} style={{ fontWeight: 'bold' }} /> : ''}
                  </Tooltip>
                </div>
              </div>
            </>)}
          title='🎫 Event'
        />

        <Table.Column
          dataIndex='tickets'
          render={(value: object, element: any) => (
            <>
              {Object.values(value ?? {}).map((item: itemTicket, index: number) => (
                <React.Fragment
                  key={index}
                >
                  <div>
                    {ticketsToColor(item, element.globalLessThen, element.modus).tags}
                    {ticketsToColor(item, element.globalLessThen, element.modus).tagArea}
                    {ticketsToColor(item, element.globalLessThen, element.modus).tagNumber}
                    {ticketsToColor(item, element.globalLessThen, element.modus).tagSingle}
                    {ticketsToColor(item, element.globalLessThen, element.modus).tagCouple}
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
          title={
            <span>🎟 Tickets ❔</span>
          }
        />
      </Table>
      <Form {...formProps} layout='vertical'>

        <Form.Item
          label='🌐 TicketOne Event Url'
          name={['url']}
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label='⏳ Frequency between each scan in seconds'
          name={['frequency']}
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='✂️ Glabal Less Than - Only show an alert if there is a value is lower of this number'
          name={['globalLessThen']}
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.List name='modusArr' initialValue={modus}>
          {(fields, { add, remove }) => (
            <Card title='🚦 Modus Operandi' size='small'>

              {fields.map((field) => (
                <Form.Item
                  key={field?.key}
                  // label={plannerData?.tickets[field?.key]}
                  // name={['modus']}
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Radio.Group name={field?.key?.toString()} onChange={onChange} defaultValue={modus?.[field?.key]?.value}>
                    <Radio value='disabled'><TagField color='default' value='⚫️ disabilitato' icon={<NotificationOutlined />} /></Radio>
                    <Radio value='notify'><TagField color='cyan' value='solo notifica' icon={<NotificationFilled />} /></Radio>
                    <Radio value='cart'><TagField color='gold' value='auto cart' icon={<ShoppingCartOutlined />} /></Radio>
                    <Radio value='buy'><TagField color='green' value='auto buy' icon={<ShoppingFilled />} />
                      {
                      // eslint-disable-next-line operator-linebreak
                      // modus[field?.key]?.value > 0 ?
                        <span style={{ fontSize: 12 }}>
                          <span>Q.tà: <Input name={'qta|' + field?.key?.toString()} onChange={onChangeInput} defaultValue={modus?.[field?.key]?.qta} style={{ fontSize: 12, width: 30, height: 20, textAlign: 'center', padding: 4, marginRight: 10 }} placeholder='qtà' /></span>
                          <span>Min: <Input name={'min|' + field?.key?.toString()} onChange={onChangeInput} defaultValue={modus?.[field?.key]?.min} style={{ fontSize: 12, width: 20, height: 20, textAlign: 'center', padding: 4, marginRight: 10, backgroundColor: '#f9f0ff' }} placeholder='min' /></span>
                          <span>Max: <Input name={'max|' + field?.key?.toString()} onChange={onChangeInput} defaultValue={modus?.[field?.key]?.max} style={{ fontSize: 12, width: 50, height: 20, textAlign: 'center', padding: 4, marginRight: 10, backgroundColor: '#f9f0ff' }} placeholder='max' /></span>
                        </span>
                        // : null
                      }
                    </Radio>
                  </Radio.Group>

                  <span>
                    {getTickets(plannerData?.tickets, field?.key)}
                  </span>
                  <MinusCircleOutlined onClick={() => { remove(field.name) }} />
                </Form.Item>
              ))}
            </Card>
          )}
        </Form.List>
      </Form>
    </Edit>
  )
}
