import type React from 'react'
import { useEffect, useState } from 'react'
import {
  type IResourceComponentsProps, useShow
} from '@refinedev/core'

import {
  Col,
  Popover,
  Radio,
  Row
} from 'antd'

import {
  DashboardTotalCountCard
} from './components'

import {
  cronoGet,
  getInstances,
  getAverage,
  cronoGetDetails,
  filterByTimeRange
} from '../../fn'

// import { AuditOutlined, /* ShopOutlined */ TeamOutlined } from '@ant-design/icons'

export const Dashboard: React.FC<IResourceComponentsProps> = () => {
  const { query } = useShow({ id: '' })

  const [selectedPeriod, setSelectedPeriod] = useState<'24h' | '7d' | '30d'>('7d')

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        query.refetch().then(console.log).catch(console.error)
      }, 60000)

      return () => { clearTimeout(timeout) }
    }
    , [query]
  )

  const data = query.data?.data ?? {}
  const { benchmark, config } = data
  // const isLoading = queryResult.isLoading

  // console.log(data, isLoading)

  const platforms = ['eventim', 'fansale', 'fansaleEvent', 'ticketmaster', 'vivaticket', 'vivaresale']

  const getSuccessFailCounts = (platform: string, hours: number): { successCount: Record<string, unknown>, failCount: Record<string, unknown> } => {
    const successScanner = filterByTimeRange(benchmark?.[`success-${platform}-scanner-it`], hours)
    const failScanner = filterByTimeRange(benchmark?.[`fail-${platform}-scanner-it`], hours)

    const successCarter = filterByTimeRange(benchmark?.[`success-${platform}-carter-it`], hours)
    const failCarter = filterByTimeRange(benchmark?.[`fail-${platform}-carter-it`], hours)

    const successBuyer = filterByTimeRange(benchmark?.[`success-${platform}-buyer-it`], hours)
    const failBuyer = filterByTimeRange(benchmark?.[`fail-${platform}-buyer-it`], hours)

    return {
      successCount: { scanner: successScanner, carter: successCarter, buyer: successBuyer },
      failCount: { scanner: failScanner, carter: failCarter, buyer: failBuyer }
    }
  }

  const hoursInPeriod = {
    '1h': 1,
    '2h': 2,
    '4h': 4,
    '12h': 12,
    '24h': 24,
    '7d': 7 * 24,
    '30d': 30 * 24
  }

  const cards = platforms.map((platform) => {
    const { successCount, failCount } = getSuccessFailCounts(platform, hoursInPeriod[selectedPeriod])

    const idScanner = 'crono-' + platform + '-scanner-it'
    const dataScanner = benchmark?.[idScanner]
    const idCarter = ['fansale', 'fansaleEvent'].includes(platform) ? 'crono-fansale-carter-it' : 'crono-' + platform + '-carter-it'
    const dataCarter = benchmark?.[idCarter]
    const idBuyer = ['eventim', 'fansale', 'fansaleEvent'].includes(platform) ? 'crono-eventim-buyer-it' : 'crono-' + platform + '-buyer-it'
    const dataBuyer = benchmark?.[idBuyer]

    const events = platform !== 'fansale' ? String(data[platform]?.length ?? '?') + ' eventi' : String(data[platform]?.length ?? '?') + ' keywords'
    let toBuy = 0
    for (const objs of data[platform] ?? []) {
      if (objs.autoBuy) {
        toBuy += 1
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        for (const obj of Object.values(objs.modus ?? {}) as Array<{ value: string, qta?: number }>) {
          if (obj.value === 'buy') {
            toBuy += obj.qta ? Number(obj.qta) : 1
          }
        }
      }
    }

    return (
      <Row gutter={[32, 32]} key={platform} style={{ marginTop: '24px' }}>
        <Col xs={24} sm={24} xl={6}>
          <DashboardTotalCountCard
            resource={{
              primaryColor: '#1677FF',
              secondaryColor: '#BAE0FF',
              icon: <img style={{ maxWidth: 24 }} title={platform} src={`https://teddy.bigmoney.biz/img/${platform}.png`} />,
              title: 'Tempi medi di ' + platform + ':'
            }}
            isLoading={false}
            one={
              <Popover
                content={
                  <pre>
                    {cronoGetDetails(dataScanner)}
                    Media: {cronoGet(dataScanner, 'ms')}
                  </pre>
                }
                title='Dettaglio tempi'
              >
                <span>🔎{cronoGet(dataScanner)}  </span>
              </Popover>
            }
            two={
              <Popover
                content={
                  <pre>
                    {dataCarter?.map((item: number, index: number) => String(index) + ') ' + String(item) + 'ms\n')}
                    Media: {getAverage(dataCarter, 'ms')}
                  </pre>
                }
                title='Dettaglio tempi'
              >
                <span>🛒{getAverage(dataCarter)}</span>
              </Popover>
            }
            three={
              <Popover
                content={
                  <pre>
                    {dataBuyer?.map((item: number, index: number) => String(index) + ') ' + String(item) + 'ms\n')}
                    Media: {getAverage(dataBuyer, 'ms')}
                  </pre>
                }
                title='Dettaglio tempi'
              >
                <span>🛍{getAverage(dataBuyer)}</span>
              </Popover>
            }
          />
        </Col>
        <Col xs={24} sm={24} xl={6}>
          <DashboardTotalCountCard
            resource={{
              icon: <img style={{ maxWidth: 24 }} title={platform} src={`https://teddy.bigmoney.biz/img/${platform}.png`} />,
              title: '☑️ Istanze attive ' + platform + ':'
            }}
            isLoading={false}
            one={
              <a href={`/status?sorters[0][field]=lastSeen&sorters[0][order]=ascend&filters[0][field]=COUNTRY&filters[0][operator]=eq&filters[0][value][0]=${'it'}&filters[1][field]=ROLE&filters[1][operator]=eq&filters[1][value][0]=${'scanner'}&filters[2][field]=PLATFORM&filters[2][operator]=eq&filters[2][value][0]=${platform}`}>
                🔎{getInstances(config, platform, 'scanner', 'it')}
              </a>
            }
            two={
              <a href={`/status?sorters[0][field]=lastSeen&sorters[0][order]=ascend&filters[0][field]=COUNTRY&filters[0][operator]=eq&filters[0][value][0]=${'it'}&filters[1][field]=ROLE&filters[1][operator]=eq&filters[1][value][0]=${'carter'}&filters[2][field]=PLATFORM&filters[2][operator]=eq&filters[2][value][0]=${platform}`}>
                🛒{getInstances(config, (['fansale', 'fansaleEvent'].includes(platform) ? 'fansale' : platform), 'carter', 'it')}
              </a>
            }
            three={
              <a href={`/status?sorters[0][field]=lastSeen&sorters[0][order]=ascend&filters[0][field]=COUNTRY&filters[0][operator]=eq&filters[0][value][0]=${'it'}&filters[1][field]=ROLE&filters[1][operator]=eq&filters[1][value][0]=${'buyer'}&filters[2][field]=PLATFORM&filters[2][operator]=eq&filters[2][value][0]=${['eventim', 'fansale', 'fansaleEvent'].includes(platform) ? 'eventim' : platform}`}>
                🛍{getInstances(config, (['eventim', 'fansale', 'fansaleEvent'].includes(platform) ? 'eventim' : platform), 'buyer', 'it')}
              </a>
            }
          />
        </Col>
        <Col xs={24} sm={24} xl={6}>
          <DashboardTotalCountCard
            resource={{
              icon: <img style={{ maxWidth: 24 }} title={platform} src={`https://teddy.bigmoney.biz/img/${platform}.png`} />,
              title: '📊 Numeri eventi ' + platform + ':'
            }}
            isLoading={false}
            one={`🎫${events}`}
            two={`🎟 ${toBuy} to buy`}
          />
        </Col>
        <Col xs={24} sm={24} xl={6}>
          <DashboardTotalCountCard
            resource={{
              icon: <img style={{ maxWidth: 24 }} title={platform} src={`https://teddy.bigmoney.biz/img/${platform}.png`} />,
              title: `✔️ Fallimenti / Successo ${selectedPeriod} ` + platform + ':'
            }}
            isLoading={false}
            one={`🔎${String(failCount.scanner)}/${String(successCount.scanner)}`}
            two={`🛍${String(failCount.buyer)}/${String(successCount.buyer)}`}
            three={`🛒${String(failCount.carter)}/${String(successCount.carter)}`}
          />
        </Col>
      </Row>
    )
  })

  return (
    <div className='page-container'>
      <div style={{ textAlign: 'right' }}>
        <span>Timeframe: <Radio.Group defaultValue='30d' size='small' onChange={(e) => { setSelectedPeriod(e.target.value) }} options={Object.keys(hoursInPeriod)} optionType='button' /></span>
      </div>

      {cards}

    </div>
  )
}
