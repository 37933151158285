import type React from 'react'
import { type IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'

import {
  Form, // Checkbox,
  // Space,
  // Icons
  // Button
  Input
} from 'antd'

export const ControlCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps /* queryResult */ } = useForm()

  return (
    <Create saveButtonProps={saveButtonProps} title='Invia comando massivo su tutte le instanze watchdog'>
      <Form {...formProps} layout='vertical'>
        <Form.Item
          label='🕹 Command'
          name='command'
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input placeholder='npm run update ; npm install' />
        </Form.Item>

      </Form>
    </Create>
  )
}
