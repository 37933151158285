import type React from 'react'
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core'
import { CustomSearchBar, filterData } from '../../components/shared/CustomSearchBar'

import {
  useTable, List, EditButton, ShowButton, DeleteButton, // UrlField,
  TagField, RefreshButton, CreateButton, CloneButton
} from '@refinedev/antd'

import {
  Table, Space, // Icons
  Popover
  /*
  *   Form,
  *   Input,
  *   Icons,
  *   Button
  */
} from 'antd'

import {
  compareFn,
  convertSeconds,
  toColor,
  toData,
  toSec
} from '../../fn'
import { useState } from 'react'

// const {
//   CheckCircleOutlined,
//   CloseCircleOutlined,
//   MinusCircleOutlined
// } = Icons

// interface objTickets {
//   color: any
//   icon: any
// }
// function ticketsToColor (s: string, oldLessThan: string): objTickets {
//   const tickets = Number(s.split(' ■ ')[1])
//   const disabledByOldLessThan = oldLessThan !== '' && oldLessThan !== null && oldLessThan !== undefined && tickets > Number(oldLessThan)
//   const color = tickets === 0 ? 'red' : disabledByOldLessThan ? 'default' : 'green'
//   const icon = tickets === 0 ? <CloseCircleOutlined /> : disabledByOldLessThan ? <MinusCircleOutlined /> : <CheckCircleOutlined />
//   return { color, icon }
// }

export const printTable = (tableProps: any): any => {
  console.log({ tableProps })
  return (
    <Table
      {...tableProps}
      rowKey='id'
    >

      <Table.Column
        dataIndex='autoCart'
        title={
          <Popover
            content={
              <div>
                <p><TagField color='red' value='🔍 solo notifica' /> Solo notifica</p>
                <p><TagField color='gold' value='🛒 auto cart' /> Auto carrella ma acquisto manuale</p>
                <p><TagField color='green' value='💰 auto buy' /> Auto carrella e auto compra</p>
                <p><TagField color='cyan' value='🦄 auto buy all' />Autocompra tutto anche i singoli</p>
              </div>
            }
            title='Legenda'
          >
            <span>🚦 Modus Operandi ❔</span>
          </Popover>
        }
        render={(value: any, element: any) => (
          <>{value
            ? element.autoBuy
              ? element.autoBuyAll
                ? <TagField color='cyan' value='🦄 auto buy all' />
                : <TagField color='green' value='💰 auto buy' />
              : <TagField color='gold' value='🛒 auto cart' />
            : <TagField color='red' value='🔍 solo notifica' />}
          </>
        )}
        sorter={{ compare: compareFn('autoCart') }}
      />

      <Table.Column
        dataIndex='positive'
        title='🔑➕ Keyword Positive'
        render={(a: string[], e: any, index: any) => (
          <>
            {a?.map((e) =>
              <h4 key={e}>🔑➕ {e}</h4>)}
          </>
        )}
        sorter={{ compare: compareFn('positive') }}
      />

      <Table.Column
        dataIndex='negative'
        title='🔑➖ Keyword Negative'
        render={(a: string[], e: any, index: any) => (
          <>
            {a?.map((e) =>
              <h4 key={e}>🔑➖ {e}</h4>)}
          </>
        )}
      />

      <Table.Column
        dataIndex='note'
        title='🗒 Note'
        render={(note: string, element: any, index: any) => (
          <div style={{ maxWidth: '300px' }}>
            <h4>🗒 {note}</h4>
          </div>
        )}
      />
      <Table.Column
        dataIndex='id'
        title='🆔 ID'
        render={(id: string, element: any, index: any) => (
          <div style={{ maxWidth: '150px' }}>
            <h4>🆔 {id}</h4>
          </div>
        )}
      />

      <Table.Column
        dataIndex='lastScan'
        render={(value: any) => (
          <span style={{ color: toColor(value) }}>⏱
            {
              // eslint-disable-next-line multiline-ternary
              (value || value === '0') ? (
                <>
                  <b>
                    {convertSeconds(toSec(value))}
                    ago
                  </b>
                  <div>{toData(value)}</div>
                </>
              )
                : (<b>Never</b>)
            }
          </span>

        )}
        title='⏱ Last Scan'
      />
      <Table.Column
        dataIndex='lastAlert'
        render={(value: any) => (
          <span>⚠️
            {
              // eslint-disable-next-line multiline-ternary
              (value || value === '0') ? (
                <>
                  <b>
                    {convertSeconds(toSec(value))}
                    ago
                  </b>
                  <div>{toData(value)}</div>
                </>
              )
                : (<b>Never</b>)
            }
          </span>

        )}
        title='⚠️ Last Alert'
        sorter={{ compare: compareFn('lastAlert') }}
      />

      <Table.Column
        dataIndex='actions'
        render={(_, record: BaseRecord) => (
          <Space>
            <EditButton
              hideText
              recordItemId={record.id}
              size='middle'
            />

            <CloneButton
              hideText
              recordItemId={record.id}
              size='middle'
            />

            <ShowButton
              hideText
              recordItemId={record.id}
              size='middle'
            />

            <DeleteButton
              hideText
              recordItemId={record.id}
              size='middle'
            />
          </Space>
        )}
        title='🕹 Actions'
      />
    </Table>
  )
}

export const FansaleList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,

    pagination: {
      mode: 'off'
    }
  })

  const [query, setQuery] = useState<any>()
  const updatedTableProps = {
    ...tableProps,
    dataSource: filterData(tableProps.dataSource, query)
  }

  return (
    <List
      headerButtons={
        <>
          {/* <Form layout="horizontal" {...tableProps}>
            <Form.Item name="q">
                <Input
                    placeholder="ID, Title, Content, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form> */}
          <CustomSearchBar
            onFilter={setQuery}
          />
          <RefreshButton
            onClick={() => { window.location.reload() }}
            size='large'
          />

          <CreateButton
            size='large'
            type='primary'
          />
        </>
      }
      title={'🤖 ' + window.location.pathname.replace('/', '').toUpperCase()}
    >

      {printTable(updatedTableProps)}
    </List>
  )
}
