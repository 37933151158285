import type React from 'react'
import { type IResourceComponentsProps, useShow } from '@refinedev/core'

import {
  Show, // ImageField,
  // TagField,
  TextField, // UrlField,
  NumberField
} from '@refinedev/antd'

import { Typography } from 'antd'

import {
// toColor,
// toData,
// toSec
} from '../../fn'

const { Title } = Typography

export const ControlShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const record = data?.data
  console.log(record)

  return (
    <Show isLoading={isLoading}>

      <Title level={5}>🎫 Instance</Title>
      <TextField value={record?.instance} />
      <Title level={5}>📡 Scanner Name</Title>
      <TextField value={record?.scannerName} />
      <Title level={5}>⏳ Scan Interval</Title>
      <NumberField value={record?.scannerInterval} />
      {/* <Title level={5}>⏱ Last Seen</Title>
      <TextField value={record?.lastSeen ? <span style={{ color: toColor(record?.lastSeen) }}><b>{toSec(record?.lastSeen)}s ago</b> {toData(record?.lastSeen)}</span> : ''} /> */}
      <Title level={5}>🖥 Enabled</Title>
      <TextField value={record?.enabled !== false ? '🟢 Abilitato' : '🔴 Disabilitato'} />
    </Show>
  )
}
