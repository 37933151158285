import type React from 'react'
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core'
import { CustomSearchBar, filterData } from '../../components/shared/CustomSearchBar'

import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  RefreshButton,
  CloneButton,
  CreateButton
  /*
   *   Form,
   *   Input,
   *   Icons,
   *   Button
   */
} from '@refinedev/antd'

import { Table, Space } from 'antd'

import { compareFn } from '../../fn'
import { useState } from 'react'

export const AccountList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,

    pagination: {
      mode: 'off'
    }
  })

  const [query, setQuery] = useState<any>()
  const updatedTableProps = {
    ...tableProps,
    dataSource: filterData(tableProps.dataSource, query)
  }

  return (
    <List
      headerButtons={
        <>
          {/* <Form layout='horizontal' {...tableProps}>
            <Form.Item name='q'>
                <Input
                    placeholder='ID, Title, Content, etc.'
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType='submit' type='primary'>
                    Filter
                </Button>
            </Form.Item>
        </Form> */}
          <CustomSearchBar
            onFilter={setQuery}
          />
          <RefreshButton
            onClick={() => {
              window.location.reload()
            }}
            size='large'
          />

          <CreateButton size='large' type='primary' />
        </>
      }
      title='🖥 Status'
    >
      <Table {...updatedTableProps} rowKey='n'>

        <Table.Column
          dataIndex='user'
          title='🎫 Email'
          render={(value: any) => <>{value}</>}
          sorter={{ compare: compareFn('user') }}
        />

        <Table.Column
          dataIndex='pwd'
          title='🔑 Password'
          render={(value: any) => <>{value}</>}
          sorter={{ compare: compareFn('pwd') }}
        />

        <Table.Column
          dataIndex='timesCarted'
          title='🛒 Times Carted'
          render={(value: any) => <>{value}</>}
          sorter={{ compare: compareFn('timesCarted') }}
        />
        <Table.Column
          dataIndex='phone'
          title='📱 Phone'
          render={(value: any) => <>{value}</>}
          sorter={{ compare: compareFn('phone') }}
        />
        <Table.Column
          dataIndex='actions'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                recordItemId={record.id}
                size='middle'
              />

              <ShowButton
                hideText
                recordItemId={record.id}
                size='middle'
              />

              <CloneButton
                hideText
                recordItemId={record.id}
                size='middle'
              />

              <DeleteButton
                hideText
                recordItemId={record.id}
                size='middle'
              />
            </Space>
          )}
          title='🕹 Actions'
        />
      </Table>
    </List>
  )
}
