import type React from 'react'
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core'
import { CustomSearchBar, filterData } from '../../components/shared/CustomSearchBar'

import {
  useTable, List, EditButton, // ShowButton,
  RefreshButton, CreateButton
} from '@refinedev/antd'

import {
  Table, Space, /*
*   Form,
*   Input,
*   Icons,
*   Button
*/
  Popover,
  Select,
  Progress,
  Tooltip
} from 'antd'

import { DesktopOutlined } from '@ant-design/icons'

import { blo } from 'blo'

import {
  compareFn,
  convertSeconds,
  toColor,
  toData,
  toSec,
  getProgressColor
} from '../../fn'
import { useState } from 'react'

export const ControlList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,

    pagination: {
      mode: 'off'
    }
  })

  const [sortField, setSortField] = useState('cpuUsage')

  const [query, setQuery] = useState<any>()
  const updatedTableProps = {
    ...tableProps,
    dataSource: filterData(tableProps.dataSource, query)
  }

  return (
    <List
      headerButtons={
        <>
          {/* <Form layout="horizontal" {...tableProps}>
            <Form.Item name="q">
                <Input
                    placeholder="ID, Title, Content, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form> */}
          <CustomSearchBar
            onFilter={setQuery}
          />
          <RefreshButton
            onClick={() => { window.location.reload() }}
            size='large'
          />

          <CreateButton
            size='large'
            type='primary'
          >
            Invia Comando Massivo
          </CreateButton>
        </>
      }
      title='🕹 Control Room'
    >

      <Table
        {...updatedTableProps}
        rowKey='n'
      >
        <Table.Column
          dataIndex='n'
          title='🔢 N°'
          sorter={{ compare: compareFn('id') }}
        />

        <Table.Column
          dataIndex='instance'
          title='🆔 Instance'
          render={(value: string) => (
            <a style={{ color: 'black' }} href={`/status/show/${value}`}>
              🎫 {value}
            </a>
          )}
          sorter={{ compare: compareFn('instance') }}
        />

        <Table.Column
          dataIndex='env'
          title='📡 Env'
          render={(value: any) => (
            <>{
              Object.keys(value ?? {})
                .map(
                  name => {
                    return <div key={name}><b>{name}:</b> <code style={{ color: 'blue' }}>{value[name]}</code></div>
                  }
                )
            }
            </>)}
          sorter={{ compare: compareFn('env') }}
        />

        <Table.Column
          dataIndex='sysInfo'
          title='🖥️ SysInfo'
          render={(value?: Record<string, any>) => (
            <pre>
              <div><b>Arch:</b> {value?.arch}</div>
              <div><b>Node Version:</b> {value?.nodeVersion}</div>
              <div><b>OS Version:</b> {value?.release}</div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 70 }}><b>RAM:</b>
                  </div>
                  <Tooltip title={`${String(value?.usedRam)} /  ${String(value?.totalRam)} GB | FREE: ${String(value?.freeRam)} GB`}>
                    <Progress
                      percent={Number(((value?.usedRam / value?.totalRam) * 100).toFixed(0))}
                      strokeColor={getProgressColor((value?.usedRam / value?.totalRam) * 100)}
                    />
                  </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 70 }}><b>CPU:</b></div>
                  <Tooltip title={`${String(value?.cpuUsage)}%`}>
                    <Progress
                      percent={value?.cpuUsage}
                      strokeColor={getProgressColor(value?.cpuUsage)}
                    />
                  </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 70 }}><b>Space:</b></div>
                  <Tooltip title={`${String(value?.usedSpace)} / ${String(value?.totalSpace)} GB | FREE: ${String(value?.freeSpace)} GB`}>
                    <Progress
                      percent={Number(((value?.usedSpace / value?.totalSpace) * 100).toFixed(0))}
                      strokeColor={getProgressColor((value?.usedSpace / value?.totalSpace) * 100)}
                    />
                  </Tooltip>
                </div>
              </div>
              <div><b>Chrome Processes:</b> {value?.chromeProcesses}</div>
              <div><b>AdsPower Processes:</b> {value?.adspowerProcesses}</div>
              <div><b>SunBrowser Processes:</b> {value?.sunbrowserProcesses}</div>
              <div><b>Total Processes:</b> {value?.totalProcesses}</div>
            </pre>
          )}
          sorter={{
            compare: compareFn('sysInfo', (e: any) => {
              if (sortField === 'cpuUsage') return e?.cpuUsage
              if (sortField === 'ram') return e?.usedRam / e?.totalRam
              if (sortField === 'space') return e?.usedSpace / e?.totalSpace
              return e?.[sortField]
            })
          }}
          filterDropdown={(props) => (
            <div style={{ padding: 8 }}>
              <Select
                placeholder='Select Sort Field'
                onChange={setSortField}
                defaultValue='cpuUsage'
                options={[
                  { label: 'CPU', value: 'cpuUsage' },
                  { label: 'RAM', value: 'ram' },
                  { label: 'Space', value: 'space' },
                  { label: 'Total Processes', value: 'totalProcesses' }
                ]}
                style={{ width: '100%' }}
              />
            </div>
          )}
        />

        <Table.Column
          dataIndex='netns'
          title='🔌 Netns'
          render={(value: any) => (<>🔌 {value}</>)}
          sorter={{ compare: compareFn('netns') }}
        />

        <Table.Column
          dataIndex='lastSeen'
          render={(value: any, element: any) => (
            <span style={{ color: element?.enabled === false ? 'gray' : toColor(value) }}>⏱
              {
                // eslint-disable-next-line multiline-ternary
                (value || value === '0') ? (
                  <>
                    <b>
                      {convertSeconds(toSec(value))}
                      ago
                    </b>

                    <div>{toData(value)}</div>
                  </>
                )
                  : (<b>Never Seen</b>)
              }
            </span>

          )}
          title='⏱ Last Seen'
          sorter={{ compare: compareFn('lastSeen') }}
        />

        <Table.Column
          dataIndex='lastStart'
          render={(value: any, element: any) => (
            <span style={{ color: element?.enabled === false ? 'gray' : toSec(value) < 300 ? '#FFBF00' : 'black' }}>🕰
              {
                // eslint-disable-next-line multiline-ternary
                (value || value === '0') ? (
                  <>
                    <b>
                      {convertSeconds(toSec(value))}
                      ago
                    </b>

                    <div>{toData(value)}</div>
                  </>
                )
                  : <b style={{ color: 'lightgray' }}>Never Started</b>
              }
            </span>

          )}
          title='🕰 Uptime'
          sorter={{ compare: compareFn('lastStart') }}
        />

        <Table.Column
          dataIndex='commitHash'
          render={(value: string, element: any) => (

            !value
              ? null
              // eslint-disable-next-line operator-linebreak
              :
              <Popover
                content={
                  <div>
                    <center style={{ padding: 20 }}>
                      <img
                        title={value}
                        src={blo(`0x${value}`, 64)}
                      />
                    </center>
                    <pre>Git Summary: {element?.gitSummary}</pre>
                  </div>
                }
                title='Versione in esecuzione'
              >

                <img
                  title={value}
                  src={blo(`0x${value}`, 32)}
                />

              </Popover>

          )}
          title={
            <Popover
              content={
                <div>
                  <p>Versione in esecuzione</p>
                </div>
              }
              title='Commit Hash'
            >
              <span>V.</span>
            </Popover>
          }
          sorter={{ compare: compareFn('commitHash') }}
        />

        <Table.Column
          dataIndex='enabled'
          title='🖥 Enabled'
          render={(value: any) => (<>🖥{value !== false ? '🟢 Abilitato' : '🔴 Disabilitato'}</>)}
          sorter={{ compare: compareFn('enabled', (enabled: any) => { return enabled !== false }) }}
        />

        <Table.Column
          dataIndex='actions'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                icon={<DesktopOutlined />}
                recordItemId={record.instance}
                size='middle'

              >
                Invia comando
              </EditButton>
              {/* <ShowButton
                hideText
                recordItemId={record.id}
                size='middle'
              /> */}
            </Space>
          )}
          title='Actions'
        />
      </Table>
    </List>
  )
}
