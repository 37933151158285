
// import { BikeWhiteIcon } from 'components'

interface TitleProps {
  collapsed: boolean
}

export const Title: React.FC<TitleProps> = (/* { collapsed } */) => {
  return (
    <div style={{
      height: '72px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      {/*             {collapsed
              ? (
                //   {<BikeWhiteIcon style={{ color: 'white', fontSize: '32px' }} />}
                  <img src="/favicon.ico" alt="Teddy" />
                )
              : (
                <img src="/favicon.ico" alt="Teddy" />
                )} */
        (
          <img
            src='/favicon.ico' alt='Teddy' style={{
              width: '32px',
              fontSize: '32px'

            }}
          />
        )
      }
    </div>
  )
}
