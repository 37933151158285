import type React from 'react'
import { type IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'

import { Form, Input, Checkbox, Space, Select } from 'antd'

import { useState } from 'react'

import envIntegrityWrap from '../../../server/envIntegrity.js'

const envIntegrity: Record<string, any> = envIntegrityWrap.envIntegrity

export const StatusCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    errorNotification: (error, values, resource) => {
      console.log(error)
      return {
        message: 'Failed',
        description: error?.response?.data?.error,
        type: 'error'
      }
    }
  })

  const [launcher, setLauncher] = useState('undefined')
  const [env, setEnv] = useState<any>({ LAUNCHER: undefined })
  const [once, setOnce] = useState(true)

  if (formProps?.initialValues && once) {
    setOnce(false)
    const enviroment: any = { LAUNCHER: undefined, ...formProps?.initialValues?.env }
    setEnv(enviroment)
    setLauncher(enviroment.LAUNCHER)
  }

  const handleLauncherChange = (launcher: any): void => {
    setLauncher(launcher)
    const enviroment: any = { LAUNCHER: launcher }
    for (const key of Object.keys(envIntegrity[launcher])) enviroment[key] = envIntegrity[launcher][key][0]
    setEnv(enviroment)
  }

  const launchers = Object.keys(envIntegrity).map(launcher => ({ value: launcher }))
  const envArr = Object.keys(env).map(key => { return { key, value: env[key] } })
  formProps?.form?.setFieldsValue({ envArr })

  const title = location.pathname.includes('edit')
    ? 'Edit'
    : location.pathname.includes('clone')
      ? 'Clone'
      : 'Create'

  return (
    <Create saveButtonProps={saveButtonProps} title={title}>
      <Form {...formProps} layout='vertical'>
        <Form.Item
          label='🎫 Instance'
          name={['instance']}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.List name={['envArr']}>
          {() => (
            <>
              {Object.keys(env).map((key, index) => (
                <Space key={key} align='baseline' style={{ display: 'flex', marginBottom: 8 }}>
                  <Form.Item
                    label='🔧 Key'
                    name={[index, 'key']}
                    initialValue={key}
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    label='🔐 Value'
                    name={[index, 'value']}
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ minWidth: 200 }}
                      placeholder='Please select a value'
                      options={
                        key === 'LAUNCHER'
                          ? launchers
                          : envIntegrity[launcher]?.[key]?.filter((e: string) => e).map((value: string) => ({ value }))
                      }
                      onChange={(value: string) => {
                        if (key === 'LAUNCHER') { handleLauncherChange(value) }
                      }}
                    />
                  </Form.Item>
                </Space>
              ))}
            </>
          )}
        </Form.List>

        <Form.Item
          label='⏳ Scan Interval'
          name={['scanInterval']}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='🔌 Netns'
          name={['netns']}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='🖥 Enabled'
          name='enable'
          initialValue={[true]}
        >
          <Checkbox.Group options={[{ label: '🖥 Abilitato', value: true }]} />
        </Form.Item>

      </Form>
    </Create>
  )
}
