
import type React from 'react'
import { useState } from 'react'

import { type IResourceComponentsProps } from '@refinedev/core'
import { Edit, useForm, TagField } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Form, Input, Radio, Card, // Space,
  Popover, Button
} from 'antd'

import { ticketsToColor, type modus, getName, type fansale, type itemTicket } from '../../fn'

import { printEdit } from '../fansale/edit'

import {
  printTable,
  imgPlatform,
  type obj
} from './list'

const {
  // IdcardTwoTone,
  EditTwoTone,
  // CheckCircleOutlined,
  CloseCircleOutlined,
  // PlusCircleOutlined,
  MinusCircleOutlined,
  // DownCircleOutlined,
  // DownCircleFilled,
  // UpCircleOutlined,
  // UpCircleFilled,
  // ShoppingOutlined,
  ShoppingFilled,
  ShoppingCartOutlined,
  // NotificationOutlined,
  NotificationFilled
  // PoweroffOutlined,
  // SearchOutlined,
  // NumberOutlined,
  // IdcardOutlined,
} = Icons

export const CountryEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps /* queryResult */ } = useForm()

  // const plannerData = queryResult?.data?.data

  const getModus = (plannerData: any): any => {
    const finalModus: Record<string, modus> = {}
    const modusObj: Record<string, modus> = plannerData?.modus ?? {}

    for (const index of Object.keys(plannerData?.tickets ?? {})) {
      const item: itemTicket = plannerData?.tickets?.[index]
      const title: string = item.title
      const price: string = item.price
      const name = getName(title, price)
      const id: string = (title + price)?.replace(/[^A-Za-z0-9]/g, '-')?.replace(/-+/g, '-')?.toLowerCase()

      const fansale: fansale = Object.values(initialValues?.fansaleArr ?? {}).find(e => e?.id?.includes(id))

      const itemInputStatus = fansale?.autoCart
        ? fansale?.autoBuy
          ? fansale?.autoBuyAll
            ? 3
            : 2
          : 1
        : 0

      finalModus[name] = {
        name: getName(title, price),
        title,
        price,
        value: modusObj?.[name]?.value ?? 'notify',
        qta: modusObj?.[name]?.qta ?? '',
        min: modusObj?.[name]?.min ?? '',
        max: modusObj?.[name]?.max ?? '',
        fansale: {
          id,
          modus: itemInputStatus,
          positive: fansale?.positive,
          negative: fansale?.negative,
          note: fansale?.note
        }
      }
      if (!fansale) delete finalModus[name].fansale
    }
    return Object.values(finalModus)
  }

  const getModusObj = (arr: any[]): object => {
    return arr.reduce((obj, item, index) => {
      const { name } = item
      // delete item.name
      obj[name] = item
      return obj
    }, {})
  }

  const getTickets = (obj: obj, key: number): any => {
    const fansale: fansale = initialValues?.[obj.platform]?.modusArr?.[key]?.fansale
    const fansaleImg = fansale ? imgPlatform({ platform: 'fansale' }, '/fansale/' + window.location.pathname.split('/')[2] + '-fansale/show/' + fansale.id) : null

    const item = Object.values(initialValues?.[obj.platform]?.tickets)?.[key]
    const { tags, tagArea, tagNumber, tagSingle, tagCouple } = ticketsToColor(item, obj.globalLessThen, getModusObj(initialValues[obj.platform]?.modusArr))
    return [imgPlatform(obj), tags, tagArea, tagNumber, tagSingle, tagCouple, fansaleImg, fansaleFields(obj, key, fansale)]
  }

  const openStatus: Record<string, boolean> = {}
  const [open, setOpen] = useState(openStatus)

  const hide = (id: string): any => {
    return (): void => {
      openStatus[id] = false
      setOpen(openStatus)
    }
  }

  const handleOpenChange = (id: string): any => {
    return (newOpen: boolean): void => {
      openStatus[id] = newOpen
      setOpen(openStatus)
    }
  }

  const fansaleFields = (obj: obj, key: number, fansale: fansale): any => {
    if (!fansale) return

    return (
      <Popover
        content={
          <span>
            {printEdit(key, true)}
            <center>
              <Button onClick={hide(fansale.id)} icon={<CloseCircleOutlined />}>Close</Button>
            </center>
          </span>
        }
        title={'🆔: ' + fansale.id}
        trigger='click'
        open={!!open[fansale.id]}
        onOpenChange={handleOpenChange(fansale.id)}
      >
        <Button size='small' icon={<EditTwoTone />}>Edit Fansale</Button>
      </Popover>

    )
  }

  const edit = (obj: obj): any => {
    if (!obj?.url) return
    return (

      <Form.List name={obj.platform}>
        {() => (
          <Card title={(() => (<>{imgPlatform(obj)} {obj.platform}</>))()} size='small'>

            <Form.Item
              label='🌐 Url'
              name={['url']}
              initialValue={obj.url}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label='⏳ Frequency between each scan in seconds'
              name={['frequency']}
              initialValue={obj.frequency}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='✂️ Glabal Less Than - Only show an alert if there is a value is lower of this number'
              name={['globalLessThen']}
              initialValue={obj.globalLessThen}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.List name='modusArr' initialValue={obj.modusArr}>
              {(fields, { add, remove }) => (
                <Card title='🚦 Modus Operandi' size='small'>

                  {fields.map((field, key) => (
                    <div key={key}>
                      <Form.Item
                        name={[key, 'value']}
                        rules={[
                          {
                            required: true
                          }
                        ]}
                        style={{ marginBottom: 0, display: 'inline-block' }}
                      >
                        <Radio.Group size='small' buttonStyle='solid'>
                          <Radio.Button value='disabled'>
                            <TagField color='default' value='disabled' icon={<>⚫️</>} style={{ margin: 0 }} />
                          </Radio.Button>
                          <Radio.Button value='notify'>
                            <TagField color='cyan' value='notify' icon={<NotificationFilled />} style={{ margin: 0 }} />
                          </Radio.Button>
                          <Radio.Button value='cart'>
                            <TagField color='gold' value='cart' icon={<ShoppingCartOutlined />} style={{ margin: 0 }} />
                          </Radio.Button>
                          <Radio.Button value='buy'>
                            <TagField color='green' value='buy' icon={<ShoppingFilled />} style={{ margin: 0 }} />
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        name={[key, 'min']}
                        rules={[{ required: false }]}
                        style={{ marginBottom: 0, display: 'inline-block', fontSize: 12, marginInlineStart: 8, verticalAlign: 'top' }}
                      >
                        {/* <span>Q.tà: <Input name={'qta|' + key?.toString()} onChange={onChangeInput(obj)} defaultValue={values[obj.platform]?.modusArr?.[key]?.qta} style={{ fontSize: 12, width: 30, height: 20, textAlign: 'center', padding: 4, marginRight: 10 }} placeholder='qtà' /></span> */}
                        <span>Min: <Input style={{ fontSize: 12, width: 20, height: 20, textAlign: 'center', padding: 4, marginRight: 10, backgroundColor: '#f9f0ff' }} placeholder='min' defaultValue={obj.modusArr[key].min} /></span>
                      </Form.Item>
                      <Form.Item
                        name={[key, 'max']}
                        rules={[{ required: false }]}
                        style={{ marginBottom: 0, display: 'inline-block', fontSize: 12, marginInlineStart: 8, verticalAlign: 'top' }}
                      >
                        <span>Max: <Input style={{ fontSize: 12, width: 50, height: 20, textAlign: 'center', padding: 4, marginRight: 10, backgroundColor: '#f9f0ff' }} placeholder='max' defaultValue={obj.modusArr[key].max} /></span>
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => { remove(key) }} style={{ marginInlineEnd: 8, marginInlineStart: 8 }} />

                      <span>
                        {getTickets(obj, key)}
                      </span>
                    </div>
                  ))}
                </Card>
              )}
            </Form.List>

          </Card>
        )}
      </Form.List>

    )
  }

  const initialValues: Record<string, obj > = { ...(formProps.initialValues ?? {}) }
  delete formProps.initialValues

  Object.keys(initialValues).forEach(key => {
    const obj = initialValues[key]
    if (!obj?.url) return
    initialValues[key].modusArr ??= getModus(obj)
  })

  // console.log({ formProps, initialValues })

  return (
    <>
      <Edit saveButtonProps={saveButtonProps}>
        {printTable({
          dataSource: [initialValues],
          pagination: false
        })}
        <Form {...formProps}>
          {Object.values(initialValues).map(edit)}
        </Form>
      </Edit>
    </>
  )
}
