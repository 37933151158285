import type React from 'react'
import { type IResourceComponentsProps, useShow } from '@refinedev/core'

import {
  Show, // ImageField,
  // TagField,
  TextField
} from '@refinedev/antd'

import { Typography } from 'antd'

import {
// toColor,
// toData,
// toSec
} from '../../fn'

const { Title } = Typography

export const AccountShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const record = data?.data
  console.log(record)

  return (
    <Show isLoading={isLoading}>

      <Title level={5}>🎫 Email</Title>
      <TextField value={record?.user} />
      <Title level={5}>📡 Password</Title>
      <TextField value={record?.pwd} />
      <Title level={5}>📡 Phone</Title>
      <TextField value={record?.phone} />
    </Show>
  )
}
