import type React from 'react'
import { type IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show, // ImageField,
  // TagField,
  // UrlField,
  // NumberField
  TextField
} from '@refinedev/antd'

import {
  printTable
} from './list'

export const FansaleShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show isLoading={isLoading}>

      {record
        // eslint-disable-next-line operator-linebreak
        ?
        // eslint-disable-next-line react/jsx-indent
        <>
          {printTable({
            dataSource: [record],
            pagination: false
          })}
        </>
        : <TextField value='🚮 Not Found' style={{ textAlign: 'center', fontSize: 32 }} />}
    </Show>
  )
}
