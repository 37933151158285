import type React from 'react'
import { type IResourceComponentsProps } from '@refinedev/core'

import {
  Edit, useForm, // Checkbox,
  TagField
} from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Form, Input, Button, Space, Radio } from 'antd'

const {
  // CheckCircleOutlined,
  // CloseCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined
} = Icons

export const printEdit = (key: number = 0, disableModus: boolean = false): any => {
  return (
    <>
      <Form.Item
        label='🚦 Modus Operandi'
        name={[key, 'fansale', 'modus']}
        rules={[
          {
            required: true
          }
        ]}
      >
        <Radio.Group disabled={disableModus}>
          <Radio value={0}><TagField color='red' value='🔍 notifica' /></Radio>
          <Radio value={1}><TagField color='gold' value='🛒 cart' /></Radio>
          <Radio value={2}><TagField color='geekblue' value='💰 buy' /></Radio>
          <Radio value={3}><TagField color='cyan' value='🦄 buy all' /></Radio>

        </Radio.Group>
      </Form.Item>

      <Form.List name={[key, 'fansale', 'positive']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space
                key={field.key}
                style={{ display: 'flex', marginBottom: 8 }}
                align='baseline'
              >
                <Form.Item
                  label='🔑🟢 Keyword Positive'
                  name={[field.name]}
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Input placeholder='Keyword Positive' />
                </Form.Item>
                <MinusCircleOutlined onClick={() => { remove(field.name) }} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type='primary'
                onClick={() => { add() }}
                icon={<PlusOutlined />}
                ghost
              >
                Add Positive Keyword Item
              </Button>
            </Form.Item>
          </>

        )}
      </Form.List>
      <Form.List name={[key, 'fansale', 'negative']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space
                key={field.key}
                style={{ display: 'flex', marginBottom: 8 }}
                align='baseline'
              >

                <Form.Item
                  {...field}
                  label='🔑🔴 Keyword Negative'
                  name={[field.name]}
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Input placeholder='Keyword Negative' />
                </Form.Item>
                <MinusCircleOutlined onClick={() => { remove(field.name) }} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type='default'
                onClick={() => { add() }}
                // block
                icon={<PlusOutlined />}
                danger
              >
                Add Negative Keyword Item
              </Button>
            </Form.Item>

          </>

        )}
      </Form.List>
      <Form.Item
        label='🗒 Note'
        name={[key, 'fansale', 'note']}
        rules={[
          {
            required: false
          }
        ]}
      >
        <Input placeholder='NOTE' />
      </Form.Item>
    </>

  )
}

export const FansaleEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps /* queryResult */ } = useForm()

  const initialValues: any = { ...(formProps.initialValues ?? {}) }

  const itemInputsPositive = initialValues?.positive
  const itemInputsNegative = initialValues?.negative
  const note = initialValues?.note
  const itemInputStatus = initialValues?.autoCart
    ? initialValues?.autoBuy
      ? initialValues?.autoBuyAll
        ? 3
        : 2
      : 1
    : 0

  formProps.initialValues = [{
    fansale:
      {
        modus: itemInputStatus,
        positive: itemInputsPositive,
        negative: itemInputsNegative,
        note
      }
  }]

  console.log({ formProps, saveButtonProps })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        {printEdit()}
      </Form>
    </Edit>
  )
}
