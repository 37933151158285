import type React from 'react'
import { type IResourceComponentsProps, useShow } from '@refinedev/core'

import {
  Show, // TagField,
  TextField
} from '@refinedev/antd'

import { Typography } from 'antd'

const { Title } = Typography

export const GsheetShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const record = data?.data ?? {}

  return (
    (
      <Show isLoading={isLoading}>
        <Title level={5}>Messaggio</Title>
        {record?.mex?.split('\n').map((value: string, key: number) => (<div key={key}><TextField style={{ textAlign: 'center', fontSize: 32 }} value={value} /></div>))}
        {
          // eslint-disable-next-line operator-linebreak
          record?.error ?

            <>
              <Title level={5}>Errore:</Title>
              <TextField style={{ textAlign: 'center', fontSize: 32 }} value={record?.error} />
            </>

            : null
        }
      </Show>
    )
  )
}
