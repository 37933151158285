import type React from 'react'
import { type IResourceComponentsProps, useShow } from '@refinedev/core'
import {
// useTable,
// Table,
// Typography
// ImageField,
// TagField,
// TextField,
// UrlField,
// NumberField
  Show
} from '@refinedev/antd'

// import {
//   ticketsToColor,
//   toColor,
//   toData,
//   toSec
// } from '../../fn'

import {
  printTable
} from './list'

export const CountryShow: React.FC<IResourceComponentsProps> = () => {
  // const { tableProps } = useTable({
  //   syncWithLocation: true,
  //   hasPagination: false
  // })
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const record: any = data?.data ?? {}

  return (
    <Show isLoading={isLoading}>
      {printTable({
        dataSource: [record],
        pagination: false
      })}
    </Show>
  )
}
